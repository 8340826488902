<template>
  <v-container fluid no-gutters>
    <B2cTabs/>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center" class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <h2 class="pa-4">
            Colors
            <v-icon @click="resetColors" color="#000" style="float: right"
              >mdi-reload</v-icon
            >
          </h2>

          <v-row class="px-4 pb-4">
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                elevation="0"
                @click="dialogPrimary = true"
                :color="generalData.colors.primary"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Primary</b></p>

              <v-dialog v-model="dialogPrimary" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Select primary color
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.primary"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogPrimary = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogPrimary = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                @click="dialogAccent = true"
                elevation="0"
                :color="generalData.colors.accent"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Accent</b></p>
              <v-dialog v-model="dialogAccent" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Select primary color
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.accent"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogAccent = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogAccent = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                @click="dialogSecondary = true"
                elevation="0"
                :color="generalData.colors.secondary"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Branding bar</b></p>
              <v-dialog v-model="dialogSecondary" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Select bottom branding bar color
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.secondary"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogSecondary = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogSecondary = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                @click="dialogDark = true"
                elevation="0"
                :color="generalData.colors.dark"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Header & Footer</b></p>
              <v-dialog v-model="dialogDark" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5"> Select color </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.dark"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogDark = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogDark = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                @click="dialogPositive = true"
                elevation="0"
                :color="generalData.colors.positive"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Positive</b></p>
              <v-dialog v-model="dialogPositive" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Select primary color
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.positive"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogPositive = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogPositive = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                @click="dialogNegative = true"
                elevation="0"
                :color="generalData.colors.negative"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Negative</b></p>
              <v-dialog v-model="dialogNegative" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Select primary color
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.negative"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogNegative = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogNegative = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                @click="dialogWarning = true"
                elevation="0"
                :color="generalData.colors.warning"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Warning</b></p>
              <v-dialog v-model="dialogWarning" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Select primary color
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.warning"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogWarning = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogWarning = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="text-center px-3">
              <v-card
                class="rounded-lg"
                @click="dialogInfo = true"
                elevation="0"
                :color="generalData.colors.info"
                style="width: 100%; height: 50px"
              >
              </v-card>
              <p class="mt-2"><b>Info</b></p>
              <v-dialog v-model="dialogInfo" persistent max-width="290">
                <v-card>
                  <v-card-title class="text-h5">
                    Select primary color
                  </v-card-title>
                  <v-card-text>
                    <v-color-picker
                      v-model="generalData.colors.info"
                      mode="hexa"
                    ></v-color-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogInfo = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      color="green darken-1"
                      outlined
                      @click="dialogInfo = false"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <v-row justify="center" class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <h2 class="pa-4">Logo & favicon</h2>

          <div class="px-4 pb-4 d-flex flex-row">
            <v-row>
              <v-col
                cols="6"
                :sm="checkReadPermission($modules.kiosk.facility.slug) ? 3 : 6"
                :md="checkReadPermission($modules.kiosk.facility.slug) ? 3 : 6"
              >
                <image-upload
                  @upload="
                    (data) => {
                      generalData.logo_image = data;
                    }
                  "
                  @remove="
                    () => {
                      generalData.logo_image = null;
                    }
                  "
                  :image_path="generalData.logo_path"
                  :height="340"
                  :width="100"
                  defaultImage="event"
                >
                </image-upload>
                <p class="text-center mt-2"><b>Logo </b>(jpg/png)</p>
              </v-col>
              <v-col
                cols="6"
                :sm="checkReadPermission($modules.kiosk.facility.slug) ? 3 : 6"
                :md="checkReadPermission($modules.kiosk.facility.slug) ? 3 : 6"
              >
                <image-upload
                  @upload="
                    (data) => {
                      generalData.favicon_image = data;
                    }
                  "
                  @remove="
                    () => {
                      generalData.favicon_image = null;
                    }
                  "
                  :image_path="generalData.favicon_path"
                  :height="340"
                  :width="100"
                  defaultImage="event"
                ></image-upload>
                <p class="text-center mt-2"><b>Favicon</b></p>
              </v-col>
              <v-col
                cols="6"
                sm="4"
                md="3"
                v-if="checkReadPermission($modules.kiosk.facility.slug)"
              >
                <image-upload
                  @upload="
                    (data) => {
                      generalData.kiosk_logo_image = data;
                    }
                  "
                  @remove="
                    () => {
                      generalData.kiosk_logo_image = null;
                    }
                  "
                  :image_path="generalData.kiosk_logo_image_path"
                  :height="340"
                  :width="100"
                  defaultImage="event"
                ></image-upload>
                <p class="text-center mt-2"><b>Kiosk Logo</b>(jpg/png)</p>
              </v-col>
              <v-col
                cols="6"
                sm="4"
                md="3"
                v-if="checkReadPermission($modules.kiosk.facility.slug)"
              >
                <image-upload
                  @upload="
                    (data) => {
                      generalData.kiosk_background_image = data;
                    }
                  "
                  @remove="
                    () => {
                      generalData.kiosk_background_image = null;
                    }
                  "
                  :image_path="generalData.kiosk_background_image"
                  :height="340"
                  :width="100"
                  defaultImage="event"
                ></image-upload>
                <p class="text-center mt-2"><b>Kiosk Background</b>(jpg/png)</p>
              </v-col>
              <v-col
                cols="6"
                :sm="checkReadPermission($modules.kiosk.facility.slug) ? 3 : 6"
                :md="checkReadPermission($modules.kiosk.facility.slug) ? 3 : 6"
              >
                <image-upload
                  @upload="
                    (data) => {
                      generalData.cover_image = data;
                    }
                  "
                  @remove="
                    () => {
                      generalData.cover_image_url = null;
                    }
                  "
                  :image_path="generalData.cover_image_url"
                  :height="340"
                  :width="100"
                  defaultImage="event"
                ></image-upload>
                <p class="text-center mt-2"><b>Qube Background Image</b></p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-col cols="12" md="6" sm="12" style="padding-left: 0">
          <v-card style="width: 100%; min-width: 300px; padding-left: 0">
            <h2 class="pa-4">Configurations</h2>
            <div class="px-4 pb-4 d-flex flex-row">
              <v-col cols="12" sm="12" md="12">
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  label="Enable Guest Checkout"
                  v-model="generalData.is_guest_checkout"
                  class="mx-4 my-0"
                ></v-switch>
                <v-switch
                  :false-value="0"
                  :true-value="1"
                  label="Enable Facility Booking Reserve"
                  v-model="generalData.is_reserve_enable"
                  class="mx-4 my-0"
                ></v-switch>
                <v-switch
                  v-if="
                    checkWritePermission($modules.b2cconfig.toggleCheckout.slug)
                  "
                  :false-value="0"
                  :true-value="1"
                  label="Show Payment Details"
                  v-model="generalData.is_checkout_enabled"
                  class="mx-4 my-0"
                ></v-switch>
                <v-switch
                  v-if="allowGolf"
                  :false-value="0"
                  :true-value="1"
                  label="Enable Golf Qube"
                  v-model="generalData.enable_golf_mode"
                  class="mx-4 my-0"
                ></v-switch>
                <v-col cols="12">
                  <v-text-field
                    label="Facility button label"
                    outlined
                    v-model="generalData.facility_button_label"
                    class="mx-4 my-0"
                  ></v-text-field>
                  <v-text-field
                    v-if="is_arabic_enabled"
                    label="Facility button label(AR)"
                    outlined
                    v-model="generalData.ar_facility_button_label"
                    class="mx-4 my-0"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Google Tag Manager Code"
                    outlined
                    v-model="generalData.gtm_code"
                    class="mx-4 my-0"
                  ></v-text-field>
                </v-col>
<!--                lead tracker button-->
                <v-switch
                    v-if="checkWritePermission($modules.leadTracking.management.slug)"
                    :false-value="0"
                    :true-value="1"
                    label="Enable Lead Tracker button"
                    v-model="generalData.enable_lead_tracker_button"
                    class="mx-4 my-0"
                ></v-switch>
                <v-col cols="12">
                  <v-text-field
                      v-if="generalData.enable_lead_tracker_button"
                      label="Lead Tracker button label"
                      outlined
                      v-model="generalData.lead_tracker_button_label"
                      class="mx-4 my-0"
                  ></v-text-field>
                  <v-text-field
                      v-if="generalData.enable_lead_tracker_button && is_arabic_enabled"
                      label="Lead Tracker button label(AR)"
                      outlined
                      v-model="generalData.ar_lead_tracker_button_label"
                      class="mx-4 my-0"
                  ></v-text-field>
                </v-col>
                <v-switch
                    :false-value="0"
                    :true-value="1"
                    label="Enable Join Now Button"
                    v-model="generalData.enable_join_now"
                    class="mx-4 my-0"
                ></v-switch>
                <v-col cols="12">
                  <v-text-field
                      v-if="generalData.enable_join_now"
                      label="Button label"
                      outlined
                      v-model="generalData.join_now_label"
                      class="mx-4 my-0"
                  ></v-text-field>
                  <v-text-field
                      v-if="generalData.enable_join_now && is_arabic_enabled"
                      label="Button label(AR)"
                      outlined
                      v-model="generalData.ar_join_now_label"
                      class="mx-4 my-0"
                  ></v-text-field>
                  <v-text-field
                      v-if="generalData.enable_join_now"
                      label="Button Link"
                      outlined
                      v-model="generalData.join_now_link"
                      :rules="[(v) => !!v || 'Join Now Link Required']"
                      class="mx-4 my-0"
                  ></v-text-field>
                </v-col>

              </v-col>
            </div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="12"
          style="min-height: 100%; padding-right: 0"
        >
          <v-card
            style="
              width: 100%;
              min-width: 300px;
              height: 100%;
              padding-right: 0;
            "
          >
            <h2 class="pa-4">Apps</h2>
            <v-col cols="12">
              <v-text-field
                label="App Store Link"
                outlined
                v-model="generalData.app_store"
                class="mx-4 my-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Google Play Store link"
                outlined
                v-model="generalData.google_store"
                class="mx-4 my-0"
              ></v-text-field>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <!-- <h2 class="pa-4">Address & links</h2> -->
          <v-row>
            <v-col>
              <h2 class="mx-4 mt-4">Website</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="URL*"
                outlined
                v-model="generalData.domain_name"
                class="mx-4 my-0"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                :items="themes"
                label="Theme"
                outlined
                v-model="generalData.colors.theme"
                class="mx-4 my-0"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <!-- <v-select
                :items="themes"
                label="Theme"
                outlined
                v-model="generalData.colors.theme"
                class="mx-4 my-0"
              ></v-select> -->

              <v-text-field
                label="Additional config flag (optional)"
                outlined
                v-model="generalData.colors.mode"
                class="mx-4 my-0"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <!--      <v-row justify="center" class="mt-10">-->
      <!--        <v-card style="width: 100%; min-width: 300px">-->
      <!--          &lt;!&ndash; <h2 class="pa-4">Address & links</h2> &ndash;&gt;-->
      <!--          <v-row>-->
      <!--            <v-col>-->
      <!--              <h2 class="mx-4 mt-4">Apps</h2>-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--          <v-row>-->
      <!--            <v-col cols="3">-->
      <!--              <v-text-field-->
      <!--                  label="App Store Link"-->
      <!--                  outlined-->
      <!--                  v-model="generalData.app_store"-->
      <!--                  class="mx-4 my-0"-->
      <!--              ></v-text-field>-->

      <!--            </v-col>-->
      <!--            <v-col cols="3">-->

      <!--              <v-text-field-->
      <!--                  label="Google Play Store link"-->
      <!--                  outlined-->
      <!--                  v-model="generalData.google_store"-->
      <!--                  class="mx-4 my-0"-->
      <!--              ></v-text-field>-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--        </v-card>-->
      <!--      </v-row>-->

      <v-row justify="center" class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <!-- <h2 class="pa-4">Address & links</h2> -->
          <v-row>
            <v-col cols="12">
              <h2 class="mx-4 mt-4">Contact information</h2>
            </v-col>
            <v-col cols="12">
              <v-switch
                :false-value="0"
                :true-value="1"
                label="Enable Footer Contact & Map Section"
                v-model="generalData.enable_footer_contact_us"
                class="mx-4 my-0"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-row v-for="(contacts, index) in emails" :key="index">
                <v-col cols="10">
                  <v-text-field
                    label="Email*"
                    outlined
                    v-model="contacts.value"
                    class="mx-4 my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="1" v-if="emails.length > 1" class="mt-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="del_btn"
                        color="red"
                        @click="removeEmail(index)"
                        x-small
                        dark
                        fab
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
              <div class="add_btn">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="blue-color"
                      fab
                      x-small
                      dark
                      @click="addEmail()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add new code
                </v-tooltip>
              </div>
            </v-col>

            <v-col md="6">
              <v-row v-for="(contacts, index) in mobiles" :key="index">
                <v-col cols="10">
                  <v-text-field
                    label="Mobile*"
                    outlined
                    v-model="contacts.value"
                    class="mx-4 my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="1" v-if="mobiles.length > 1" class="mt-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="del_btn"
                        color="red"
                        @click="removeNumber(index)"
                        x-small
                        dark
                        fab
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
              <div class="add_btn">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="blue-color"
                      fab
                      x-small
                      dark
                      @click="addNumber()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add new code
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <v-row justify="center" class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <!-- <h2 class="pa-4">Address & links</h2> -->
          <v-row>
            <v-col class="text-center">
              <h2 class="ma-4">Social links</h2>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    label="Facebook"
                    outlined
                    v-model="generalData.social_media.facebook"
                    class="ml-4 my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    label="Instagram"
                    outlined
                    v-model="generalData.social_media.instagram"
                    class="ml-2 my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    label="Twitter"
                    outlined
                    v-model="generalData.social_media.twitter"
                    class="ml-4 my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    label="Youtube"
                    outlined
                    v-model="generalData.social_media.youtube"
                    class="ml-2 my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    label="Tiktok"
                    outlined
                    v-model="generalData.social_media.tiktok"
                    class="ml-4 my-0"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <v-text-field
                    label="Snapchat"
                    outlined
                    v-model="generalData.social_media.snapchat"
                    class="ml-2 my-0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="text-center">
              <h2 class="ma-4">Address</h2>
              <v-textarea
                class="mx-4"
                outlined
                v-model="generalData.contact_address"
                rows="5"
                name="input-7-4"
                label="Address"
                value=""
                height="270px"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <v-row justify="center" class="mt-10">
        <v-card
          style="width: 100%; min-width: 300px"
          v-if="generalData.qube_configuration == 'afh'"
        >
          <!-- <h2 class="pa-4">Address & links</h2> -->
          <v-row>
            <v-col>
              <h2 class="mx-4 mt-4">Subscriber Form</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch
                :false-value="0"
                :true-value="1"
                label="Enable Subscriber Form"
                v-model="generalData.enable_subscriber"
                class="mx-4 my-0"
              ></v-switch>
            </v-col>
            <v-col cols="6" v-if="generalData.enable_subscriber">
              <v-text-field
                label="Title*"
                outlined
                v-model="generalData.subscriber_form.title"
                class="mx-4 my-0"
                :rules="[(v) => !!v || 'Title is required']"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6" v-if="generalData.enable_subscriber">
              <v-text-field
                dir="rtl"
                label="Title (AR)"
                outlined
                v-model="generalData.subscriber_form.ar_title"
                class="mx-4 my-0"
                :rules="[(v) => !!v || 'Title (AR) is required']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card style="width: 100%; min-width: 300px" class="mt-10">
          <!-- <h2 class="pa-4">Address & links</h2> -->
          <v-row>
            <v-col>
              <h2 class="mx-4 mt-4">Find Us Section</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch
                :false-value="0"
                :true-value="1"
                label="Enable Find Us Section"
                v-model="generalData.enable_findus"
                class="mx-4 my-0"
              ></v-switch>
            </v-col>
            <v-col cols="12" v-if="generalData.enable_findus">
              <h3 class="pa-4">Description</h3>
              <div class="px-4 pb-4 d-flex flex-row flex-wrap">
                <text-editor
                  @complete="setFindUsContent"
                  :message="generalData.fs_description"
                  style="width: 100%"
                />
              </div>
            </v-col>
            <v-col cols="12" v-if="generalData.enable_findus">
              <h3 class="pa-4">Description (AR)</h3>
              <div class="px-4 pb-4 d-flex flex-row flex-wrap">
                <text-editor
                  dir="rtl"
                  @complete="setFindUsContentAr"
                  :message="generalData.ar_fs_description"
                  style="width: 100%"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-row>

      <v-row
        justify="center"
        class="mt-10"
        v-if="generalData.qube_configuration == 'rm'"
      >
        <v-card style="width: 100%; min-width: 300px">
          <h2 class="pa-4">Footer Cover Image</h2>
          <div class="px-4 pb-4 d-flex flex-row">
            <v-col cols="6" sm="4" md="4">
              <image-upload
                @upload="
                  (data) => {
                    generalData.footer_cover_image = data;
                  }
                "
                @remove="
                  () => {
                    generalData.footer_cover_image = null;
                  }
                "
                :image_path="generalData.footer_cover_image_path"
                :height="340"
                :width="100"
                defaultImage="event"
              >
              </image-upload>
              <p class="text-center mt-2">
                <b>Full Width Footer Cover Image </b>(jpg/png)
              </p>
              <p
                v-if="generalData.footer_cover_image_path"
                class="text-center mt-1"
                @click="removeFooterCoverImage"
                style="color: red; cursor: pointer"
              >
                Remove
              </p>
            </v-col>
          </div>
        </v-card>
      </v-row>

      <v-row class="mt-10">
        <h2>Venue Policies</h2>
      </v-row>

      <v-row class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <h2 class="pa-4">Terms and Conditions</h2>
          <div class="px-4 pb-4 d-flex flex-row flex-wrap" style="width: 100%">
            <text-editor
              @complete="setEmailContent"
              :message="generalData.tnc"
              style="width: 100%"
            />
          </div>
        </v-card>
      </v-row>
      <v-row class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <h2 class="pa-4">Terms and Conditions (Ar)</h2>
          <div class="px-4 pb-4 d-flex flex-row flex-wrap" style="width: 100%">
            <text-editor
              @complete="setArTncContent"
              :message="generalData.ar_tnc"
              style="width: 100%"
            />
          </div>
        </v-card>
      </v-row>
      <v-row class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <h2 class="pa-4">Qube Policy</h2>
          <div class="px-4 pb-4 d-flex flex-row flex-wrap" style="width: 100%">
            <text-editor
              @complete="setQubePolicyContent"
              :message="generalData.qube_policy"
              style="width: 100%"
            />
          </div>
        </v-card>
      </v-row>

      <div>
        <div v-for="(policy, index) in generalData.policies" :key="index">
          <v-row class="mt-10">
            <div style="position: absolute; right: 5px; z-index: 2">
              <v-tooltip>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    fab
                    x-small
                    dark
                    @click="removePolicy(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Add timing
              </v-tooltip>
            </div>

            <v-card style="width: 100%; min-width: 300px">
              <!-- <h2 class="pa-4">Terms and Conditions</h2> -->
              <v-text-field
                label="Title"
                outlined
                style="width: 40%"
                v-model="generalData.policies[index].title"
                class="mx-4 mt-8 mb-0"
              ></v-text-field>
              <div
                class="px-4 pb-4 d-flex flex-row flex-wrap"
                style="width: 100%"
              >
                <text-editor
                  v-if="generalData.prefill_policies[index].content"
                  @complete="
                    (val) => {
                      setPolicyContent(val, index);
                    }
                  "
                  :message="generalData.prefill_policies[index].content"
                  style="width: 100%"
                />
              </div>
            </v-card>
          </v-row>
        </div>
        <v-row>
          <v-col cols="6" class="pr-0"> </v-col>
          <v-col cols="1" class="pl-0">
            <div class="" style="margin-left: auto; margin-right: auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="blue-color"
                    fab
                    x-small
                    dark
                    @click="addNewPolicy()"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Add timing
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="5"> </v-col>
        </v-row>
      </div>

      <v-row class="mt-10">
        <v-card style="width: 100%; min-width: 300px">
          <h2 class="pa-4">Gallery images</h2>

          <div class="px-4 pb-4 d-flex flex-row flex-wrap" style="width: 100%">
            <div
              class="pr-4 pt-4"
              style="width: 150px"
              v-for="(img, index) in generalData.gallery"
              :key="index"
            >
              <image-upload
                @upload="
                  (data) => {
                    if (
                      !generalData.gallery[index].image &&
                      !generalData.gallery[index].path
                    ) {
                      addGalleryImageHolder();
                    }
                    generalData.gallery[index].image = data;
                  }
                "
                @remove="
                  () => {
                    generalData.gallery[index].image = null;
                    removeGalleryImageHolder(index);
                  }
                "
                :image_path="generalData.gallery[index].path"
                :height="150"
                :width="150"
                style="width: 150px"
                defaultImage="event"
              >
              </image-upload>
              <p
                v-if="generalData.gallery.length - 1 > index"
                class="text-center mt-1"
                @click="removeGalleryImageHolder(index)"
                style="color: red; cursor: pointer"
              >
                Remove
              </p>
            </div>
            <p
              v-if="generalData.gallery.length < 2"
              style="margin-top: 60px; margin-left: 20px"
            >
              Add the first image to show next slot
            </p>
          </div>
        </v-card>
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-btn class="ma-2 white--text blue-color" @click="submitData"
          >Save configurations</v-btn
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import TextEditor from "@/components/Marketing/TextEditor";
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";

export default {
  components: {
    B2cTabs,
    TextEditor,
  },
  mounted() {
    this.btnShow = true;
    this.getData();
  },
  data() {
    return {
      valid: true,
      allowGolf: false,
      page: 1,
      membershipList: [],
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      themes: ["full", "mid", "lite", "demo"],
      btnShow: false,
      membershipStatus: "1",
      venueServiceIds: [],
      defaultColors: {
        colors: {
          primary: "#007FFF",
          secondary: "#0D1F2D",
          accent: "#0D1F2D",
          dark: "#000000",
          positive: "#52f480",
          negative: "#F93943",
          info: "#47A3FF",
          warning: "#F8C462",
          theme: "full",
        },
      },
      policyObject: {
        title: "Title",
        content: "<h1>Policy content</h1>",
      },
      generalData: {
        colors: {
          primary: "#007FFF",
          secondary: "#0D1F2D",
          accent: "#0D1F2D",
          dark: "#000000",
          positive: "#52f480",
          negative: "#F93943",
          info: "#47A3FF",
          warning: "#F8C462",
          theme: "full",
          mode: "",
          google_store: null,
          app_store: null,
        },
        social_media: {
          facebook: "www.facebook.com",
          instagram: "www.instagram.com",
          twitter: "www.twitter.com",
          youtube: "www.youtube.com",
          tiktok: "www.tiktok.com",
          snapchat: "www.snapchat.com",
        },
        contact_address: "",
        contact_email: "",
        contact_mobile: "",
        domain_name: "http://localhost:8083",

        logo_image: null,
        logo_path: "",
        kiosk_logo_image: null,
        kiosk_logo_image_path: "",
        kiosk_background_image: "",
        cover_image: null,
        cover_image_url: "",
        favicon_image: null,
        favicon_path: "",
        fonts: "Inter",
        facility_button_label: "Book facility",
        ar_facility_button_label: "",
        enable_lead_tracker_button:false,
        lead_tracker_button_label:"Start the Quiz",
        ar_lead_tracker_button_label:"",
        enable_join_now:false,
        join_now_label:"Join Now",
        ar_join_now_label:"",
        join_now_link:"",
        tnc: "",
        ar_tnc: "",
        qube_policy: "",
        updated_tnc: "",
        policies: [],
        prefill_policies: [],
        gallery: [
          {
            image: null,
            path: "",
          },
        ],
        is_guest_checkout: 0,
        enable_golf_mode: 0,
        is_reserve_enable: 0,
        is_checkout_enabled: 0,
        is_retail_enabled: 0,
        gtm_code: null,
        enable_subscriber: 0,
        subscriber_form: {
          title: null,
          ar_title: null,
        },
        enable_footer_contact_us: 1,
        footer_cover_image: null,
        footer_cover_image_path: "",
        footer_cover_image_delete: false,
        enable_findus: 0,
        findus_section: {
          description: "",
          ar_description: "",
        },
        qube_configuration: null,
        fs_description: "",
        ar_fs_description: "",
      },
      prefill_policies: [],
      dialogPrimary: false,

      dialogSecondary: false,
      dialogAccent: false,
      dialogDark: false,
      dialogPositive: false,
      dialogNegative: false,
      dialogInfo: false,
      dialogWarning: false,
      emails: [],
      mobiles: [],
      deleted_mobiles: [],
      deleted_emails: [],
      is_arabic_enabled: 0,
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    addNumber() {
      this.mobiles.push({
        type: "mobile",
        value: null,
      });
    },
    removeNumber(index) {
      if (this.mobiles[index].id) {
        this.deleted_mobiles.push(this.mobiles[index].id);
      }
      this.mobiles.splice(index, 1);
    },
    addEmail() {
      this.emails.push({
        type: "email",
        value: null,
      });
    },
    removeEmail(index) {
      if (this.emails[index].id) {
        this.deleted_emails.push(this.emails[index].id);
      }
      this.emails.splice(index, 1);
    },
    resetColors() {
      this.generalData.colors = this.defaultColors.colors;
    },

    addNewPolicy() {
      this.generalData.policies.push({
        title: "Title",
        content: "<h1>Policy content</h1>",
      });

      this.generalData.prefill_policies.push({
        title: "Title",
        content: "<h1>Policy content</h1>",
      });
    },
    removePolicy(index) {
      this.generalData.policies.splice(index, 1);
      this.generalData.prefill_policies.splice(index, 1);
    },
    getData() {
      this.deleted_mobiles = [];
      this.deleted_emails = [];
      this.showLoader("Loading");

      this.$http
        .get(`venues/b2c/configurations`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            console.log(data, "data");
            this.is_arabic_enabled = data.enable_arabic;
            this.generalData.qube_configuration = data.qube_configuration;
            this.allowGolf = data.qube_configuration == "golf" ? 1 : 0;
            this.generalData.colors.primary = data.colors.primary;
            this.generalData.colors.secondary = data.colors.secondary;
            this.generalData.colors.accent = data.colors.accent;
            this.generalData.colors.positive = data.colors.positive;
            this.generalData.colors.negative = data.colors.negative;
            this.generalData.colors.warning = data.colors.warning;
            this.generalData.colors.info = data.colors.info;
            this.generalData.colors.dark = data.colors.dark;
            this.generalData.colors.theme = data.colors.theme;

            if (data.colors.mode) {
              this.generalData.colors.mode = data.colors.mode;
            }

            this.generalData.social_media.facebook = data.social_media.facebook;
            this.generalData.social_media.instagram =
              data.social_media.instagram;
            this.generalData.social_media.twitter = data.social_media.twitter;

            this.generalData.social_media.youtube = data.social_media.youtube;
            this.generalData.social_media.tiktok = data.social_media.tiktok;
            this.generalData.social_media.snapchat = data.social_media.snapchat;

            this.generalData.enable_lead_tracker_button = data.enable_lead_tracker_button;
            this.generalData.lead_tracker_button_label = data.lead_tracker_button_label;
            this.generalData.ar_lead_tracker_button_label = data.ar_lead_tracker_button_label;


            this.generalData.enable_join_now = data.enable_join_now;
            this.generalData.join_now_label = data.join_now_label;
            this.generalData.ar_join_now_label = data.ar_join_now_label;
            this.generalData.join_now_link = data.join_now_link;

            this.generalData.facility_button_label = data.facility_button_label;
            this.generalData.ar_facility_button_label =
              data.ar_facility_button_label;
            this.generalData.gtm_code = data.gtm_code;
            this.generalData.app_store = data.app_store;
            this.generalData.google_store = data.google_store;

            this.generalData.policies = [];
            this.generalData.prefill_policies = [];

            if (
              data.contacts.filter((ele) => {
                return ele.type == "email";
              }).length > 0
            ) {
              this.emails = data.contacts.filter((ele) => {
                return ele.type == "email";
              });
            } else {
              this.addEmail();
            }
            if (
              data.contacts.filter((ele) => {
                return ele.type == "mobile";
              }).length > 0
            ) {
              this.mobiles = data.contacts.filter((ele) => {
                return ele.type == "mobile";
              });
            } else {
              this.addNumber();
            }
            if (data && data.policies) {
              data.policies.forEach((inputData) => {
                if (inputData) {
                  this.generalData.policies.push({
                    title: inputData.title,
                    content: inputData.content,
                  });

                  this.generalData.prefill_policies.push({
                    title: inputData.title,
                    content: inputData.content,
                  });

                  this.prefill_policies.push(inputData.content);
                }
              });
            }

            this.generalData.domain_name = data.domain_name;
            this.generalData.contact_address = data.contact_address;
            this.generalData.contact_email = data.contact_email;
            this.generalData.contact_mobile = data.contact_mobile;

            this.generalData.logo_path = data.logo_image_url;
            this.generalData.kiosk_logo_image_path = data.kiosk_logo_image_url;
            this.generalData.kiosk_background_image =
              data.kiosk_background_image;
            this.generalData.cover_image_url = data.cover_image_url;
            this.generalData.favicon_path = data.favicon_image_url;

            this.generalData.tnc = data.tnc;
            this.generalData.updated_tnc = data.tnc;
            this.generalData.ar_tnc = data.ar_tnc;
            this.generalData.qube_policy = data.qube_policy;

            // console.log("TNC::", this.generalData.updated_tnc);
            this.generalData.is_guest_checkout = data.is_guest_checkout;
            this.generalData.enable_golf_mode = data.enable_golf_mode;
            this.generalData.is_reserve_enable = data.is_reserve_enable;
            this.generalData.is_checkout_enabled = data.is_checkout_enabled;
            this.generalData.is_retail_enabled = data.is_retail_enabled;
            this.generalData.enable_footer_contact_us =
              data.enable_footer_contact_us;
            this.generalData.footer_cover_image_path =
              data.footer_cover_image_url;

            this.generalData.enable_subscriber = data.enable_subscriber;
            if (data.subscriber_form && data.subscriber_form != "null") {
              this.generalData.subscriber_form = JSON.parse(
                data.subscriber_form
              );
            }

            this.generalData.enable_findus = data.enable_findus;
            if (data.findus_section) {
              this.generalData.findus_section = JSON.parse(data.findus_section);
              if (this.generalData.findus_section.description) {
                this.generalData.fs_description =
                  this.generalData.findus_section.description;
              }
              if (this.generalData.findus_section.ar_description) {
                this.generalData.ar_fs_description =
                  this.generalData.findus_section.ar_description;
              }
            }

            if (data.gallery) {
              let gallery = JSON.parse(data.gallery);
              this.generalData.gallery = [];
              gallery.forEach((img) => {
                this.generalData.gallery.push({
                  image: null,
                  path: img,
                });
              });

              this.generalData.gallery.push({
                image: null,
                path: "",
              });
            }
          }

          this.hideLoader();
        })
        .catch((error) => {
          console.log(error);
          this.hideLoader();
        });
    },
    addGalleryImageHolder() {
      this.generalData.gallery.push({
        image: null,
        path: "",
      });
    },

    removeGalleryImageHolder(index) {
      this.generalData.gallery.splice(index, 1);
    },
    removeFooterCoverImage() {
      this.generalData.footer_cover_image = null;
      this.generalData.footer_cover_image_path = null;
      this.generalData.footer_cover_image_delete = true;
    },
    setEmailContent(content) {
      this.generalData.updated_tnc = content;
    },
    setArTncContent(content) {
      this.generalData.ar_tnc = content;
    },
    setQubePolicyContent(content) {
      this.generalData.qube_policy = content;
    },
    setFindUsContent(content) {
      this.generalData.findus_section.description = content;
    },
    setFindUsContentAr(content) {
      this.generalData.findus_section.ar_description = content;
    },
    setPolicyContent(content, index) {
      this.generalData.policies[index].content = content;
    },
    submitData() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Saving...");
      if (this.generalData.domain_name) {
        let formData = new FormData();

        if (this.generalData.fonts) {
          formData.append("fonts", this.generalData.fonts);
        }
        formData.append(`enable_lead_tracker_button`, this.generalData.enable_lead_tracker_button);

        if (this.generalData.lead_tracker_button_label) {
          formData.append(
            "lead_tracker_button_label",
            this.generalData.lead_tracker_button_label
          );
        }
        if (this.generalData.ar_lead_tracker_button_label) {
          formData.append(
            "ar_lead_tracker_button_label",
            this.generalData.ar_lead_tracker_button_label
          );
        }

        formData.append(`enable_join_now`, this.generalData.enable_join_now);
        if (this.generalData.join_now_label) {
          formData.append(
              "join_now_label",
              this.generalData.join_now_label
          );
        }
        if (this.generalData.ar_join_now_label) {
          formData.append(
              "ar_join_now_label",
              this.generalData.ar_join_now_label
          );
        }
        if (this.generalData.join_now_link) {
          formData.append(
              "join_now_link",
              this.generalData.join_now_link
          );
        }



        if (this.generalData.facility_button_label) {
          formData.append(
            "facility_button_label",
            this.generalData.facility_button_label
          );
        }
        if (this.generalData.ar_facility_button_label) {
          formData.append(
            "ar_facility_button_label",
            this.generalData.ar_facility_button_label
          );
        }
        let gtm_code = null;
        if (this.generalData.gtm_code) {
          gtm_code = this.generalData.gtm_code;
        }
        formData.append("gtm_code", gtm_code);

        if (this.generalData.app_store) {
          formData.append("app_store", this.generalData.app_store);
        }
        if (this.generalData.google_store) {
          formData.append("google_store", this.generalData.google_store);
        }

        formData.append("gtm_code", gtm_code);
        if (this.generalData.tnc || this.generalData.updated_tnc) {
          if (this.generalData.tnc != this.generalData.updated_tnc) {
            formData.append("tnc", this.generalData.updated_tnc);
          }
        }
        if (this.generalData.ar_tnc) {
          formData.append("ar_tnc", this.generalData.ar_tnc);
        }
        if (this.generalData.qube_policy) {
          formData.append("qube_policy", this.generalData.qube_policy);
        }
        if (this.generalData.logo_image) {
          formData.append("logo_image", this.generalData.logo_image);
        }

        if (this.generalData.favicon_image) {
          formData.append("favicon_image", this.generalData.favicon_image);
        }

        if (this.generalData.kiosk_logo_image) {
          formData.append(
            "kiosk_logo_image",
            this.generalData.kiosk_logo_image
          );
        }
        if (this.generalData.kiosk_background_image) {
          formData.append(
            "kiosk_background_image",
            this.generalData.kiosk_background_image
          );
        }

        if (this.generalData.cover_image) {
          formData.append("cover_image", this.generalData.cover_image);
        }

        formData.append("contact_address", this.generalData.contact_address);
        this.emails.forEach((email, index) => {
          if (email.id) {
            formData.append(`b2c_emails_id[${index}]`, email.id);
          }
          formData.append(
            `b2c_emails[${index}]`,
            email.value == "null" || email.value == null ? "" : email.value
          );
        });

        this.mobiles.forEach((mobiles, index) => {
          if (mobiles.id) {
            formData.append(`b2c_mobiles_id[${index}]`, mobiles.id);
          }
          // formData.append(`b2c_mobiles[${index}]`, mobiles.value);
          formData.append(
            `b2c_mobiles[${index}]`,
            mobiles.value == "null" || mobiles.value == null
              ? ""
              : mobiles.value
          );
        });

        this.deleted_mobiles.forEach((deleted_mobiles, index) => {
          formData.append(`deleted_b2c_mobiles[${index}]`, deleted_mobiles);
        });

        this.deleted_emails.forEach((deleted_emails, index) => {
          formData.append(`deleted_b2c_emails[${index}]`, deleted_emails);
        });

        if (this.generalData.domain_name) {
          formData.append("domain_name", this.generalData.domain_name);
        }

        if (this.generalData.social_media.facebook) {
          formData.append(
            "social_media[facebook]",
            this.generalData.social_media.facebook
          );
        } else {
          formData.append("social_media[facebook]", "https://www.facebook.com");
        }

        if (this.generalData.social_media.instagram) {
          formData.append(
            "social_media[instagram]",
            this.generalData.social_media.instagram
          );
        } else {
          formData.append(
            "social_media[instagram]",
            "https://www.instagram.com"
          );
        }

        if (this.generalData.social_media.twitter) {
          formData.append(
            "social_media[twitter]",
            this.generalData.social_media.twitter
          );
        } else {
          formData.append("social_media[twitter]", "https://www.twitter.com");
        }

        if (this.generalData.social_media.youtube) {
          formData.append(
            "social_media[youtube]",
            this.generalData.social_media.youtube
          );
        } else {
          formData.append("social_media[youtube]", "");
        }
        if (this.generalData.social_media.tiktok) {
          formData.append(
            "social_media[tiktok]",
            this.generalData.social_media.tiktok
          );
        } else {
          formData.append("social_media[tiktok]", "");
        }
        if (this.generalData.social_media.snapchat) {
          formData.append(
            "social_media[snapchat]",
            this.generalData.social_media.snapchat
          );
        } else {
          formData.append("social_media[snapchat]", "");
        }

        if (this.generalData.colors.primary) {
          formData.append("colors[primary]", this.generalData.colors.primary);
        }

        if (this.generalData.colors.secondary) {
          formData.append(
            "colors[secondary]",
            this.generalData.colors.secondary
          );
        }

        if (this.generalData.colors.accent) {
          formData.append("colors[accent]", this.generalData.colors.accent);
        }

        if (this.generalData.colors.dark) {
          formData.append("colors[dark]", this.generalData.colors.dark);
        }

        if (this.generalData.colors.positive) {
          formData.append("colors[positive]", this.generalData.colors.positive);
        }

        if (this.generalData.colors.negative) {
          formData.append("colors[negative]", this.generalData.colors.negative);
        }

        if (this.generalData.colors.info) {
          formData.append("colors[info]", this.generalData.colors.info);
        }

        if (this.generalData.colors.warning) {
          formData.append("colors[warning]", this.generalData.colors.warning);
        }

        if (this.generalData.colors.theme) {
          formData.append("colors[theme]", this.generalData.colors.theme);
        }

        if (this.generalData.colors.mode) {
          formData.append("colors[mode]", this.generalData.colors.mode);
        }

        this.generalData.gallery.forEach((image, index) => {
          if (index < this.generalData.gallery.length - 1) {
            formData.append(
              `gallery[${index}]`,
              image.image ? image.image : image.path
            );
          }
        });

        this.generalData.policies.forEach((policy, index) => {
          formData.append(
            `policies[${index}][title]`,
            policy.title ? policy.title : ""
          );

          formData.append(
            `policies[${index}][content]`,
            policy.content ? policy.content : ""
          );
        });

        if (this.generalData.policies.length <= 0) {
          formData.append(`policies`, []);
        }

        /** subscriber form */
        if (this.generalData.enable_subscriber) {
          formData.append("enable_subscriber", true);
        } else {
          formData.append("enable_subscriber", false);
        }
        if (this.generalData.subscriber_form) {
          formData.append(
            "subscriber_form[title]",
            this.generalData.subscriber_form.title
          );
          formData.append(
            "subscriber_form[ar_title]",
            this.generalData.subscriber_form.ar_title
          );
        }
        /** Findus section */
        if (this.generalData.enable_findus) {
          formData.append("enable_findus", true);
        } else {
          formData.append("enable_findus", false);
        }
        if (this.generalData.findus_section) {
          formData.append(
            "findus_section[description]",
            this.generalData.findus_section.description
          );
          formData.append(
            "findus_section[ar_description]",
            this.generalData.findus_section.ar_description
          );
        }

        formData.append(
          `is_guest_checkout`,
          this.generalData.is_guest_checkout
        );
        formData.append(`enable_golf_mode`, this.generalData.enable_golf_mode);
        formData.append(
          `is_reserve_enable`,
          this.generalData.is_reserve_enable
        );
        formData.append(
          `is_checkout_enabled`,
          this.generalData.is_checkout_enabled
        );
        formData.append(
          `is_retail_enabled`,
          this.generalData.is_retail_enabled
        );
        formData.append(
          `enable_footer_contact_us`,
          this.generalData.enable_footer_contact_us
        );
        if (this.generalData.footer_cover_image) {
          formData.append(
            "footer_cover_image",
            this.generalData.footer_cover_image
          );
        } else {
          if (this.generalData.footer_cover_image_delete) {
            formData.append("footer_cover_image_delete", true);
          }
        }

        this.$http
          .post(`venues/b2c/configurations`, formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.getData();
              this.showSuccess("Configuration saved successfully.");
            }
            this.hideLoader();
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
      } else {
        this.showError("URL is mandatory");
        this.hideLoader();
      }
      // formData.append('colors', )
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
